import React from 'react';

import { Typography, Button } from '@mui/material';

import { RoomTypeID } from '@kamernet/core/ApiClient/Kamernet';
import { useIntl } from '@kamernet/core/Intl';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { getListingTypeSlugById } from '@kamernet/core/Routing/dynamicRouterMatcher';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import { CommonStyles } from '@kamernet/styles/CommonStyles';

import { RoomTypeIDPluralTranslations } from '@kamernet/modules/Listing/translations';

import styles from './LinkNest.module.css';

const roomTypeAndCity = (roomType: string, cityName: string) => {
  return `${roomType.toLowerCase()}-${cityName.toLowerCase()}`;
};

export const LinkNest = () => {
  const { T, locale } = useIntl();

  return (
    <PageLayout.SectionContainer className={styles.root}>
      <Typography variant="h4" className={CommonStyles.margin_bottom_3}>
        {T('LBL_HOME_LINK_NEST_TITLE')}
      </Typography>
      <div className={styles.content}>
        {/* Generic */}
        <div>
          <Typography variant="h5" className={styles.title}>
            {T('home_page.link_nest.properties_column.title')}
          </Typography>
          <div className={styles.links}>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    T('LBL_NETHERLANDS'),
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.properties_column.link_text',
                T('LBL_NETHERLANDS'),
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'amsterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.properties_column.link_text',
                'Amsterdam',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'utrecht',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Utrecht')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'rotterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.properties_column.link_text',
                'Rotterdam',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'den-haag',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Den Haag')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'delft',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Delft')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'groningen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.properties_column.link_text',
                'Groningen',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'haarlem',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Haarlem')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'leiden',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Leiden')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'amstelveen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.properties_column.link_text',
                'Amstelveen',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    T('listings_page.url.listing_type.generic'),
                    'diemen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.properties_column.link_text', 'Diemen')}
            </Button>
          </div>
        </div>
        {/* Apartments */}
        <div>
          <Typography variant="h5" className={styles.title}>
            {T(RoomTypeIDPluralTranslations[RoomTypeID.Apartment])}
          </Typography>
          <div className={styles.links}>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    T('LBL_NETHERLANDS'),
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.apartments_column.link_text',
                T('LBL_NETHERLANDS'),
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'amsterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.apartments_column.link_text',
                'Amsterdam',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'utrecht',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Utrecht')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'rotterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.apartments_column.link_text',
                'Rotterdam',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'den-haag',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Den Haag')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'delft',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Delft')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'groningen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.apartments_column.link_text',
                'Groningen',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'haarlem',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Haarlem')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'leiden',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Leiden')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'amstelveen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.apartments_column.link_text',
                'Amstelveen',
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Apartment, locale),
                    'diemen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.apartments_column.link_text', 'Diemen')}
            </Button>
          </div>
        </div>
        {/* Rooms */}
        <div>
          <Typography variant="h5" className={styles.title}>
            {T(RoomTypeIDPluralTranslations[RoomTypeID.Room])}
          </Typography>
          <div className={styles.links}>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    T('LBL_NETHERLANDS'),
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.rooms_column.link_text',
                T('LBL_NETHERLANDS'),
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'amsterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Amsterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'utrecht',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Utrecht')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'rotterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Rotterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'den-haag',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Den Haag')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'delft',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Delft')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'groningen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Groningen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'haarlem',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Haarlem')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'leiden',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Leiden')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'amstelveen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Amstelveen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Room, locale),
                    'diemen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.rooms_column.link_text', 'Diemen')}
            </Button>
          </div>
        </div>
        {/* Studios */}
        <div>
          <Typography variant="h5" className={styles.title}>
            {T(RoomTypeIDPluralTranslations[RoomTypeID.Studio])}
          </Typography>
          <div className={styles.links}>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    T('LBL_NETHERLANDS'),
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.studios_column.link_text',
                T('LBL_NETHERLANDS'),
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'amsterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Amsterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'utrecht',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Utrecht')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'rotterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Rotterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'den-haag',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Den Haag')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'delft',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Delft')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'groningen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Groningen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'haarlem',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Haarlem')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'leiden',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Leiden')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'amstelveen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Amstelveen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.Studio, locale),
                    'diemen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.studios_column.link_text', 'Diemen')}
            </Button>
          </div>
        </div>
        {/* Student housing */}
        <div>
          <Typography variant="h5" className={styles.title}>
            {T(RoomTypeIDPluralTranslations[RoomTypeID.StudentResidence])}
          </Typography>
          <div className={styles.links}>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    T('LBL_NETHERLANDS'),
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T(
                'home_page.link_nest.students_column.link_text',
                T('LBL_NETHERLANDS'),
              )}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'amsterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Amsterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'utrecht',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Utrecht')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'rotterdam',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Rotterdam')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'den-haag',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Den Haag')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'delft',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Delft')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'groningen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Groningen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'haarlem',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Haarlem')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'leiden',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Leiden')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'amstelveen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Amstelveen')}
            </Button>
            <Button
              component={NavLink.Anchor}
              href={ROUTES.ListingSearchCity.url(
                {
                  typeAndCity: roomTypeAndCity(
                    getListingTypeSlugById(RoomTypeID.StudentResidence, locale),
                    'diemen',
                  ),
                },
                locale,
                'prefix',
              )}
              size="small"
              variant="text"
            >
              {T('home_page.link_nest.students_column.link_text', 'Diemen')}
            </Button>
          </div>
        </div>
      </div>
    </PageLayout.SectionContainer>
  );
};
