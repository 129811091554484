import React from 'react';

import { Typography } from '@mui/material';

import { useIntl } from '@kamernet/core/Intl';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import { useMediaIsDesktop } from '@kamernet/utilities/Media/useMediaIsDesktop';

import { CardSlider, CardSliderItem } from '@kamernet/components/CardSlider';

import Hiw1 from '../../images/hiw1.webp';
import Hiw2 from '../../images/hiw2.webp';
import Hiw3 from '../../images/hiw3.webp';

import styles from './HowKamernetWorks.module.css';

export const HowKamernetWorks = () => {
  const { T, locale } = useIntl();

  const isDesktop = useMediaIsDesktop();

  return (
    <PageLayout.SectionContainer
      id="how-kamernet-works"
      data-testid="section/how-kamernet-works"
      className={styles.root}
    >
      <div className={styles.header}>
        <Typography variant="h3">
          {T('LBL_LANDINGPAGE_SEARCH_HIW_HEADER')}
        </Typography>
      </div>

      {/* todo: Modify background color of CardSliderItem */}
      <CardSlider visibleItemsCount={isDesktop ? 'auto' : 1}>
        <NavLink.Anchor
          href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
          underline="none"
        >
          <CardSliderItem
            src={Hiw1.src}
            label={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD1_TITLE')}
            content={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD1_TEXT')}
            lazyLoadCardImage
          />
        </NavLink.Anchor>
        <NavLink.Anchor
          href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
          underline="none"
        >
          <CardSliderItem
            src={Hiw2.src}
            label={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD2_TITLE')}
            content={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD2_TEXT')}
            lazyLoadCardImage
          />
        </NavLink.Anchor>
        <NavLink.Anchor
          href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
          underline="none"
        >
          <CardSliderItem
            src={Hiw3.src}
            label={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD3_TITLE')}
            content={T('LBL_LANDINGPAGE_SEARCH_HIW_CARD3_TEXT')}
            lazyLoadCardImage
          />
        </NavLink.Anchor>
      </CardSlider>
      <div className={styles.header}>
        <NavLink.Anchor
          href={ROUTES.HowDoesItWorkTenant.url({}, locale)}
          className={styles.readMore}
        >
          <Typography variant="h6">{T('LBL_LEARN_MORE')}</Typography>
        </NavLink.Anchor>
      </div>
    </PageLayout.SectionContainer>
  );
};
