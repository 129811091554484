import React from 'react';
import { useIntl } from '@kamernet/core/Intl';
import { Button, Typography } from '@mui/material';
import { PageLayout } from '@kamernet/core/PageLayout';

import styles from './SafetyTips.module.css';

export const SafetyTips = () => {
  const { T } = useIntl();

  return (
    <PageLayout.SectionContainer
      id="safety-tips"
      data-testid="section/safety-tips"
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.card}>
          <Typography variant="h5">{T('LBL_INDEX_PROMO_LEFT_TEXT')}</Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className={styles.button}
            href="/tips/international-students/housing/8-tips-to-stay-safe-while-searching-for-a-home-online"
          >
            {T('LBL_INDEX_PROMO_LEFT_CTA')}
          </Button>
        </div>
      </div>
    </PageLayout.SectionContainer>
  );
};
