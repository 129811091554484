import React from 'react';
import { useIntl } from '@kamernet/core/Intl';
import { Button, Typography } from '@mui/material';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';

import styles from './Welcome.module.css';

export const Welcome = () => {
  const { T, locale } = useIntl();

  return (
    <PageLayout.SectionContainer
      id="welcome"
      data-testid="section/welcome"
      className={styles.root}
    >
      <div className={styles.container}>
        <div className={styles.welcome}>
          <Typography variant="h3" className={styles.welcomeTitle}>
            {T('LBL_LANDINGPAGE_OFFER_HEADER')}
          </Typography>
          <div className={styles.statements}>
            <div className={styles.statementBlock}>
              <Typography className={styles.statementElement} variant="body2">
                {T('LBL_INDEX_SEO1')}
              </Typography>
              <Typography className={styles.statementElement} variant="body2">
                {T('LBL_INDEX_SEO2')}
              </Typography>
            </div>
            <div className={styles.statementBlock}>
              <Typography className={styles.statementElement} variant="body2">
                {T('LBL_INDEX_SEO3')}
              </Typography>
              <Typography className={styles.statementElement} variant="body2">
                {T('LBL_INDEX_SEO4')}
              </Typography>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <Typography variant="h4">{T('LBL_INDEX_SEO_TITLE2')}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {T('LBL_INDEX_SEO_TEXT2')}
          </Typography>
          <Button
            variant="contained"
            size="large"
            className={styles.cardButton}
            component={NavLink.Anchor}
            href={ROUTES.Search.url({}, locale)}
          >
            {T('LBL_SEARCH')}
          </Button>
        </div>
      </div>
    </PageLayout.SectionContainer>
  );
};
