/* eslint-disable custom/no-restricted-translation-jsx-untranslated-children-text */
import React from 'react';

import { Typography } from '@mui/material';

import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { PageLayout } from '@kamernet/core/PageLayout';

import styles from './BrandedContent.module.css';

export const BrandedContent = () => {
  const { locale } = useIntl();

  return locale === Locale.en ? (
    <PageLayout.SectionContainer className={styles.root}>
      <Typography variant="h3" component="h1" className={styles.title}>
        Welcome to Kamernet.nl: The Best Housing Website in the Netherlands
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        At Kamernet.nl, we are committed to helping you find the perfect housing
        in the Netherlands. As one of the leading housing websites in the
        Netherlands, we offer a comprehensive selection of rental options that
        cater to diverse needs and preferences. Whether you&apos;re looking for
        short-term rentals or long-term accommodation, our platform is designed
        to fulfil your housing needs.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Why Choose Kamernet.nl for Your Housing Needs?
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet.nl is a one-stop housing solution for expats and locals alike,
        providing extensive listings for rental accommodations in the
        Netherlands. Our platform is recognized among the best rental websites
        in the Netherlands for its user-friendly interface and reliable property
        listings. We ensure that every listing is vetted for quality, so you can
        trust that you&apos;re seeing the best options available.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Find Affordable Housing for Expats and Students
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet understands the unique needs of expats and offers specialized
        listings to help you settle comfortably. Whether you&apos;re searching
        for expat housing in the Netherlands or affordable student housing in
        the Netherlands, we have you covered. Our curated selection includes
        everything from short-term rentals to more permanent housing solutions,
        making it easy for you to find a place that feels like home.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Discover Your Ideal Rental with Kamernet.nl
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet.nl stands out for its commitment to quality and trust. As one
        of the best housing websites in the Netherlands, we ensure that all our
        listings are accurate and up-to-date. Our goal is to provide you with a
        seamless and trustworthy experience, helping you find your ideal rental
        with ease. Begin your search today and discover why Kamernet.nl is the
        preferred choice for so many seeking housing in the Netherlands.
      </Typography>
    </PageLayout.SectionContainer>
  ) : (
    <PageLayout.SectionContainer className={styles.root}>
      <Typography variant="h3" component="h1" className={styles.title}>
        Welkom bij Kamernet.nl: De Beste Huizensite van Nederland
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Bij Kamernet.nl zijn we toegewijd om u te helpen de perfecte woning in
        Nederland te vinden. Als een van de toonaangevende huizensites in
        Nederland bieden we een uitgebreide selectie huurwoningen die aan
        uiteenlopende behoeften en voorkeuren voldoen. Of u nu op zoek bent naar
        korte termijn huur of langdurige accommodatie, ons platform is ontworpen
        om aan uw huisvestingsbehoeften te voldoen.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Waarom Kiezen voor Kamernet.nl voor Uw Huisvestingsbehoeften?
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet.nl is een one-stop woningoplossing voor zowel expats als
        locals, met uitgebreide lijsten van huuraccommodaties in Nederland. Ons
        platform wordt erkend als een van de beste huurwebsites in Nederland
        vanwege de gebruiksvriendelijke interface en betrouwbare
        woningaanbiedingen. We zorgen ervoor dat elke aanbieding op kwaliteit is
        gecontroleerd, zodat u erop kunt vertrouwen dat u de beste opties ziet.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Vind betaalbare huisvesting voor expats en studenten
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet begrijpt de unieke behoeften van expats en biedt
        gespecialiseerde lijsten om u te helpen comfortabel te settelen. Of u nu
        op zoek bent naar expat huisvesting in Nederland of betaalbare
        studentenkamers in Nederland, wij hebben wat u nodig heeft. Onze
        zorgvuldig samengestelde selectie omvat alles, van korte termijn verhuur
        tot meer permanente huisvestingsoplossingen, waardoor het gemakkelijk
        voor u is om een plek te vinden die als thuis aanvoelt.
      </Typography>
      <Typography variant="h4" component="h2" className={styles.subtitle}>
        Ontdek jouw ideale huurwoning met Kamernet.nl
      </Typography>
      <Typography variant="body2" className={styles.paragraph}>
        Kamernet.nl onderscheidt zich door zijn toewijding aan kwaliteit en
        vertrouwen. Als een van de beste huizensites in Nederland zorgen we
        ervoor dat al onze aanbiedingen nauwkeurig en up-to-date zijn. Ons doel
        is om u een naadloze en betrouwbare ervaring te bieden, zodat u met
        gemak uw ideale huurwoning kunt vinden. Begin vandaag nog met uw
        zoektocht en ontdek waarom Kamernet.nl de voorkeurskeuze is voor zoveel
        mensen die op zoek zijn naar huisvesting in Nederland.
      </Typography>
    </PageLayout.SectionContainer>
  );
};
