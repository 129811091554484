import React from 'react';

import { Typography, Card, CardContent, CardMedia } from '@mui/material';

import styles from './CardSliderItem.module.css';

interface CardSliderItemProps {
  component?: React.ElementType;
  src: string;
  label: string;
  content?: string;
  children?: React.ReactNode;
  overrideClassName?: string;
  lazyLoadCardImage?: boolean;
}

export const CardSliderItem = ({
  component,
  src,
  label,
  content,
  children,
  overrideClassName,
  lazyLoadCardImage = false,
}: CardSliderItemProps) => {
  return (
    <Card className={overrideClassName || styles.root} elevation={0}>
      <CardMedia
        component={component || 'img'}
        image={src}
        title={label}
        className={styles.media}
        loading={lazyLoadCardImage ? 'lazy' : 'eager'}
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {label}
        </Typography>
        <Typography variant="body3" color="text.secondary">
          {content}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};
