import React from 'react';

import { PageLayout } from '@kamernet/core/PageLayout';
import { Button, ButtonBase, Typography } from '@mui/material';
import { useIntl } from '@kamernet/core/Intl';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import { ROUTES } from '@kamernet/core/Routing';
import styles from './LandlordButtons.module.css';

export const LandlordButtons = () => {
  const { T, locale } = useIntl();

  return (
    <PageLayout.SectionContainer
      className={styles.root}
      containerClassName={styles.sectionContainer}
    >
      <ButtonBase className={styles.rentButton} disableRipple>
        <Typography variant="buttonMedium">
          {T('LBL_BREADCRUMB_RENT')}
        </Typography>
      </ButtonBase>
      <Button
        className={styles.rentOutButton}
        variant="contained"
        color="tertiary"
        component={NavLink.Anchor}
        href={ROUTES.RentOutRoom.url({}, locale)}
      >
        {T('LBL_RENT_OUT')}
      </Button>
    </PageLayout.SectionContainer>
  );
};
