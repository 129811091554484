import React from 'react';

import { Typography, Button } from '@mui/material';

import { RoomTypeID } from '@kamernet/core/ApiClient/Kamernet';
import { useIntl } from '@kamernet/core/Intl';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { getListingTypeSlugById } from '@kamernet/core/Routing/dynamicRouterMatcher';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import { useMediaIsDesktop } from '@kamernet/utilities/Media/useMediaIsDesktop';

import { CardSlider, CardSliderItem } from '@kamernet/components/CardSlider';

import Amsterdam from './images/Amsterdam.webp';
import Nijmegen from './images/Nijmegen.webp';
import Rotterdam from './images/Rotterdam.webp';
import Utrecht from './images/Utrecht.webp';
import styles from './PopularCities.module.css';

export interface PopularCitiesProps {
  popularCitiesCount?: number[];
}

const roomTypeAndCity = (roomtype: string, cityname: string) => {
  return `${roomtype}-${cityname}`;
};

export const PopularCities = ({ popularCitiesCount }: PopularCitiesProps) => {
  const { T, locale } = useIntl();
  const isDesktop = useMediaIsDesktop();

  return (
    <PageLayout.SectionContainer
      id="popular-cities"
      data-testid="section/popular-cities"
      className={styles.root}
    >
      <div className={styles.header}>
        <Typography variant="h3">{T('LBL_INDEX_CITIES_TITLE')}</Typography>
      </div>

      <CardSlider visibleItemsCount={isDesktop ? 'auto' : 1}>
        <NavLink.Anchor
          href={ROUTES.ListingSearchCity.url(
            {
              typeAndCity: roomTypeAndCity(
                getListingTypeSlugById(RoomTypeID.Room, locale),
                'amsterdam',
              ),
            },
            locale,
          )}
          underline="none"
        >
          <CardSliderItem src={Amsterdam.src} label={T('Amsterdam')}>
            <Typography variant="h6">
              {T('LBL_HOMES_AVAILABLE', popularCitiesCount?.[0])}
            </Typography>
          </CardSliderItem>
        </NavLink.Anchor>

        <NavLink.Anchor
          href={ROUTES.ListingSearchCity.url(
            {
              typeAndCity: roomTypeAndCity(
                getListingTypeSlugById(RoomTypeID.Room, locale),
                'utrecht',
              ),
            },
            locale,
          )}
          underline="none"
        >
          <CardSliderItem src={Utrecht.src} label={T('Utrecht')}>
            <Typography variant="h6">
              {T('LBL_HOMES_AVAILABLE', popularCitiesCount?.[1])}
            </Typography>
          </CardSliderItem>
        </NavLink.Anchor>

        <NavLink.Anchor
          href={ROUTES.ListingSearchCity.url(
            {
              typeAndCity: roomTypeAndCity(
                getListingTypeSlugById(RoomTypeID.Room, locale),
                'nijmegen',
              ),
            },
            locale,
          )}
          underline="none"
        >
          <CardSliderItem src={Nijmegen.src} label={T('Nijmegen')}>
            <Typography variant="h6">
              {T('LBL_HOMES_AVAILABLE', popularCitiesCount?.[2])}
            </Typography>
          </CardSliderItem>
        </NavLink.Anchor>

        <NavLink.Anchor
          href={ROUTES.ListingSearchCity.url(
            {
              typeAndCity: roomTypeAndCity(
                getListingTypeSlugById(RoomTypeID.Room, locale),
                'rotterdam',
              ),
            },
            locale,
          )}
          underline="none"
        >
          <CardSliderItem src={Rotterdam.src} label={T('Rotterdam')}>
            <Typography variant="h6">
              {T('LBL_HOMES_AVAILABLE', popularCitiesCount?.[3])}
            </Typography>
          </CardSliderItem>
        </NavLink.Anchor>
      </CardSlider>
      <div className={styles.header}>
        <Button
          component={NavLink.Anchor}
          variant="outlined"
          size="medium"
          href={ROUTES.Search.url({}, locale)}
        >
          {T('LBL_INDEX_CITIES_LINK')}
        </Button>
      </div>
    </PageLayout.SectionContainer>
  );
};
