import React from 'react';

import { Paper, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { PageLayout } from '@kamernet/core/PageLayout';

import { ListingSearchBar } from '@kamernet/modules/Listing/components/ListingSearchBar';

import styles from './Search.module.css';

export const Search = () => {
  const { T, locale } = useIntl();
  const theme = useTheme();
  const isMediaSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const searchBar = (
    <ListingSearchBar isLocationRequired disableUrlUpdateOnFilterChange />
  );

  return (
    <PageLayout.SectionContainer
      className={styles.root}
      containerClassName={styles.sectionContainer}
    >
      {isMediaSmDown ? (
        <React.Fragment>
          <Typography variant="h3" className={styles.title}>
            {T('LBL_INDEX_SEARCH_BUBBLE1')}
          </Typography>
          {searchBar}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className={
              locale === Locale.en
                ? styles.generationKamernetEn
                : styles.generationKamernetNl
            }
          />
          <Paper className={styles.searchBarPaper}>{searchBar}</Paper>
        </React.Fragment>
      )}
    </PageLayout.SectionContainer>
  );
};
