import React from 'react';
import { IconButton } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import throttle from 'lodash-es/throttle';

import styles from './CardSlider.module.css';

export const GALLERY_ITEM_SPACING = 16;

export interface CardSliderProps {
  visibleItemsCount?: number | 'auto';
  children: React.ReactElement[];
}

export const CardSlider = ({
  visibleItemsCount = 'auto',

  children,
}: CardSliderProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const [scrollState, setScrollState] = React.useState<
    'first' | 'last' | 'middle'
  >('first');

  const gotForward = React.useCallback(() => {
    scrollContainerRef.current?.scrollBy(1, 0);
  }, []);

  const goBack = React.useCallback(() => {
    scrollContainerRef.current?.scrollBy(-1, 0);
  }, []);

  React.useEffect(() => {
    scrollContainerRef.current?.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    scrollContainerRef.current?.style.setProperty(
      '--x-gallery-item-count',
      String(children.length),
    );
  }, [children.length]);

  const actualVisibleItemsCount = React.useMemo(
    () => (visibleItemsCount === 'auto' ? children.length : visibleItemsCount),
    [children.length, visibleItemsCount],
  );

  const navigationNeeded = React.useMemo(
    () => children.length > actualVisibleItemsCount,
    [actualVisibleItemsCount, children.length],
  );

  const setupCardItemSize = React.useCallback(() => {
    const containerWidth = containerRef.current!.offsetWidth;

    const itemWidth =
      actualVisibleItemsCount === 1
        ? (containerWidth * 2) / 3
        : containerWidth / actualVisibleItemsCount -
          (GALLERY_ITEM_SPACING * (actualVisibleItemsCount - 1)) /
            actualVisibleItemsCount;

    scrollContainerRef.current!.style.setProperty(
      '--x-gallery-item-width',
      `${itemWidth}px`,
    );
  }, [actualVisibleItemsCount]);

  React.useEffect(() => {
    setupCardItemSize();
    window.visualViewport?.addEventListener('resize', setupCardItemSize);
    return () => {
      window.visualViewport?.removeEventListener('resize', setupCardItemSize);
    };
  }, [setupCardItemSize]);

  const setupScrollStatus = React.useMemo(
    () =>
      throttle(() => {
        if (scrollContainerRef.current) {
          const { offsetWidth, scrollWidth, scrollLeft } =
            scrollContainerRef.current;

          window.requestAnimationFrame(() => {
            if (scrollLeft === 0) {
              setScrollState('first');
            } else if (scrollLeft === scrollWidth - offsetWidth) {
              setScrollState('last');
            } else {
              setScrollState('middle');
            }
          });
        }
      }, 400),
    [],
  );

  React.useEffect(() => {
    if (!navigationNeeded) {
      return () => {};
    }

    setupScrollStatus();

    scrollContainerRef.current?.addEventListener('scroll', setupScrollStatus);

    return () =>
      scrollContainerRef.current?.removeEventListener(
        'scroll',
        setupScrollStatus,
      );
  }, [navigationNeeded, setupScrollStatus]);

  return (
    <div ref={containerRef} className={styles.root}>
      <div ref={scrollContainerRef} className={styles.scrollContainer}>
        {children}
      </div>

      {navigationNeeded && (
        <React.Fragment>
          <IconButton
            className={styles.goBackButton}
            hidden={scrollState === 'first'}
            data-testid="CardSlider/Previous"
            onClick={goBack}
          >
            <ArrowBackIosNew />
          </IconButton>

          <IconButton
            className={styles.goForwardButton}
            hidden={scrollState === 'last'}
            data-testid="CardSlider/Next"
            onClick={gotForward}
          >
            <ArrowForwardIos />
          </IconButton>
        </React.Fragment>
      )}
    </div>
  );
};

// @todo support click to drag functionality
