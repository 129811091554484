import React from 'react';

import { Button, Typography, Container } from '@mui/material';
import classnames from 'classnames';

import { AuthContext } from '@kamernet/core/Auth/AuthContext';
import { useIntl } from '@kamernet/core/Intl';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { NavLink } from '@kamernet/core/Routing/NavLink';
import { CommonStyles } from '@kamernet/styles/CommonStyles';

import styles from './RentForFree.module.css';

export const RentForFree = () => {
  const { T, locale } = useIntl();
  const { isAuthenticated } = AuthContext.useContext();

  return (
    <PageLayout.SectionContainer
      className={styles.root}
      data-testid="section/rent-for-free"
    >
      <Container className={styles.container}>
        <div>
          <Typography variant="h3">
            {T('LBL_HOME_REDESIGN_LANDLORD_TITLE')}
          </Typography>
          <Typography
            variant="body2"
            className={classnames(
              CommonStyles.padding_top_2,
              CommonStyles.padding_bottom_2,
            )}
          >
            {T('LBL_HOME_REDESIGN_LANDLORD_DESCRIPTION')}
          </Typography>
          <Button
            variant="contained"
            color="tertiary"
            size="large"
            component={NavLink.Anchor}
            href={
              isAuthenticated
                ? ROUTES.LCFStart.url({}, locale)
                : ROUTES.RentOutRoom.url({}, locale)
            }
          >
            {T('LBL_PLACE_ADVERT')}
          </Button>
        </div>
        <NavLink.Anchor
          href={ROUTES.RentOutRoom.url({}, locale)}
          className={styles.readMore}
          variant="h6"
        >
          {T('LBL_INDEX_FIRST_ADVERT_READMORE')}
        </NavLink.Anchor>
      </Container>
    </PageLayout.SectionContainer>
  );
};
