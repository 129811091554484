import React from 'react';

import { NextPage } from 'next';

import { ListingRequest } from '@kamernet/core/ApiClient/Kamernet';
import { useIntl } from '@kamernet/core/Intl';
import { logger } from '@kamernet/core/Logger';
import { PageLayout } from '@kamernet/core/PageLayout';
import { ROUTES } from '@kamernet/core/Routing';
import { useMediaIsDesktop } from '@kamernet/utilities/Media/useMediaIsDesktop';

import { definePageLoader } from '@kamernet/modules/definePageLoader';
import { ListingSearchCriteriaPartial } from '@kamernet/modules/Listing/components/ListingSearchBar/types';
import { ListingsProvider } from '@kamernet/modules/Listing/context/ListingsProvider';

import { BrandedContent } from './components/BrandedContent';
import { HowKamernetWorks } from './components/HowKamernetWorks';
import { LandlordButtons } from './components/LandlordButtons';
import { LinkNest } from './components/LinkNest';
import { PopularCities } from './components/PopularCities';
import { RentForFree } from './components/RentForFree';
import { SafetyTips } from './components/SafetyTips';
import { Search } from './components/Search';
import { Welcome } from './components/Welcome';
import OpenGraphPicture from './images/OpenGraphPicture.webp';

interface HomeProps {
  searchCriteria: ListingSearchCriteriaPartial | null;
}

export const getServerSideProps = definePageLoader<HomeProps>(async ctx => {
  try {
    const {
      location,
      radiusId,
      maxRentalPriceId,
      surfaceMinimumId,
      listingTypeIds,
    } = await ctx.req.apiClient.listing.getListingSearchCriteria();

    return {
      props: {
        searchCriteria: {
          location,
          radiusId,
          maxRentalPriceId,
          surfaceMinimumId,
          listingTypeIds,
        },
      },
    };
  } catch (ex) {
    logger.error(`Home page - Failed to fetch ListingSearchCriteria: ${ex}`);

    return {
      props: {
        searchCriteria: null,
      },
    };
  }
});

const Home: NextPage<HomeProps> = ({ searchCriteria }) => {
  const [popularCitiesCount] = React.useState<number[]>([224, 94, 50, 159]);
  const { T } = useIntl();
  const isDesktop = useMediaIsDesktop();

  return (
    <PageLayout
      pageTitle={T('LBL_LANDINGPAGE_SEARCH_TITLE')}
      metaDescription={T('LBL_LANDINGPAGE_SEARCH_META')}
      route={ROUTES.Home}
      ogImage={OpenGraphPicture.src}
    >
      <PageLayout.MainRaw>
        <LandlordButtons />
        <ListingsProvider
          initialListings={null}
          initialFilters={{ ...(searchCriteria as ListingRequest) }}
        >
          <Search />
        </ListingsProvider>
        {isDesktop && <RentForFree />}
        <PopularCities popularCitiesCount={popularCitiesCount} />
        <HowKamernetWorks />
        <SafetyTips />
        <Welcome />
        <BrandedContent />
        <LinkNest />
      </PageLayout.MainRaw>
    </PageLayout>
  );
};

export default Home;
